import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu, onBoardingMenu, reviewMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import Logout from '../pages/presentation/auth/Logout';
import ForgotPassword from '../pages/presentation/auth/ForgotPassword';
import OnBoardingPage from '../pages/presentation/onBoarding/OnBoardingSteps';
import ReviewPage from '../pages/presentation/review-page/ReviewPage';
import ResetPassword from '../pages/presentation/auth/ResetPassword';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	// SERVICE_PROVIDER: lazy(
	// 	() => import('../pages/presentation/service-management/ServiceProviders'),
	// ),
	SERVICE_PROVIDER_DETAIL: lazy(
		() => import('../pages/presentation/service-management/ServiceProviderDetail'),
	),
	SERVICES: lazy(() => import('../pages/presentation/service-management/Services')),
	SERVICE_DETAILS: lazy(() => import('../pages/presentation/service-management/ServiceDetail')),
	USER_MANAGEMENT: lazy(() => import('../pages/presentation/user-management/UserManagementPage')),
	USER_DETAIL: lazy(() => import('../pages/presentation/user-management/UserDetailPage')),
	MASSEUSE_MANAGEMENT: lazy(
		() => import('../pages/presentation/masseuse-management/MasseuseManagement'),
	),
	MASSEUSE_DETAILS: lazy(
		() => import('../pages/presentation/masseuse-management/MasseuseDetails'),
	),
	MASSAGE_DETAILS: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/service-management/MassageDetail'
			),
	),
	ADD_MASSAGE: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/AddMassageForm/AddMassageForm'
			),
	),
	PROFILE_MANAGEMENT: lazy(
		() => import('../pages/presentation/profile-management/ProfileManagement'),
	),
	FEATURED_DETAIL_MANAGEMENT: lazy(
		() => import('../pages/presentation/profile-management/FeaturedDetailPage'),
	),
	SERVICE_MANAGEMENT: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/service-management/ServiceManagement'
			),
	),
	FEATURE_MANAGEMENT: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/feature-management/FeatureManagement'
			),
	),
	HABIT_KEY_REQUEST: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/habit-key-management/HabitKeyRequestPage'
			),
	),
	FEATURED_HABIT_KEY: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/habit-key-management/HabitKeyRequestDetailPage'
			),
	),
	BOOKING: lazy(() => import('../pages/presentation/booking-management/Bookings')),
	ADD_BOOKING: lazy(() => import('../pages/presentation/booking-management/AddBooking')),
	PAST_BOOKING: lazy(() => import('../pages/presentation/booking-management/PastBookings')),
	BOOKING_DETAILS: lazy(() => import('../pages/presentation/booking-management/BookingDetail')),
	BOOKINGS_CALENDER: lazy(
		() => import('../pages/presentation/booking-management/BookingsCalender'),
	),
	REVIEWS_MANAGEMENT: lazy(
		() => import('../pages/presentation/reviews-management/ReviewsManagementPage'),
	),
	MASSAGE_CENTER_REVIEW_DETAIL: lazy(
		() =>
			import(
				'../pages/presentation/reviews-management/massage-center-review/MassageCenterReviewDetail'
			),
	),
	MASSEUSE_REVIEW_DETAIL: lazy(
		() =>
			import('../pages/presentation/reviews-management/masseuse-review/MasseuseReviewDetail'),
	),
	SERVICE_REVIEW_DETAIL: lazy(
		() => import('../pages/presentation/reviews-management/service-review/ServiceReviewDetail'),
	),
	PAST_REVIEW_DETAIL: lazy(
		() => import('../pages/presentation/reviews-management/PastReviewDetailPage'),
	),
	FEATURE_REVIEWS_MANAGEMENT: lazy(
		() =>
			import(
				'../pages/presentation/profile-and-service-management/feature-management/FeaturedServiceReview'
			),
	),
	NOTIFICATION_MANAGEMENT: lazy(
		() => import('../pages/presentation/notification-management/NotificationManagementPage'),
	),
	FINANCE_INVOICES_MANAGEMENT: lazy(
		() => import('../pages/presentation/finance-management/InvoicesPage'),
	),
	FINANCE_PAYMENTS_MANAGEMENT: lazy(
		() => import('../pages/presentation/finance-management/FinanceManagementPage'),
	),
	INVOICE_DETAILS: lazy(
		() => import('../pages/presentation/finance-management/InvoiceDetailPage'),
	),
	HELP_AND_SUPPORT: lazy(() => import('../pages/presentation/help&Support/Help&Support')),
	GENERAL_PROMOTIONS: lazy(() => import('../pages/presentation/promotions/GeneralPromotions')),
	FAQs: lazy(() => import('../pages/presentation/help&Support/faqs/FAQs')),
	CONTACT_QUERIES: lazy(
		() => import('../pages/presentation/help&Support/contact-queries/ContactQueries'),
	),
	CONTACT_QUERIES_DETAIL: lazy(
		() => import('../pages/presentation/help&Support/contact-queries/ContactQueryDetail'),
	),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardPagesMenu.serviceManagement.path,
		element: <LANDING.SERVICES />,
	},
	{
		path: `${dashboardPagesMenu.serviceDetail.path}/:sid`,
		element: <LANDING.SERVICE_DETAILS />,
	},
	{
		path: dashboardPagesMenu.userManagement.path,
		element: <LANDING.USER_MANAGEMENT isFluid />,
	},
	{
		path: `${dashboardPagesMenu.userDetail.path}/:id`,
		element: <LANDING.USER_DETAIL />,
	},
	{
		path: dashboardPagesMenu.masseuseManagement.path,
		element: <LANDING.MASSEUSE_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.masseuseDetails.path}/:id`,
		element: <LANDING.MASSEUSE_DETAILS />,
	},
	{
		path: dashboardPagesMenu.addAMassage.path,
		element: <LANDING.ADD_MASSAGE id={' '} />,
	},
	// {
	// 	path: dashboardPagesMenu.helpAndSupport.path,
	// 	element: <LANDING.HELP_AND_SUPPORT />,
	// },
	{
		path: dashboardPagesMenu.promotions.subMenu.generalPromotions.path,
		element: <LANDING.GENERAL_PROMOTIONS />,
	},
	{
		path: dashboardPagesMenu.profileManagement.path,
		element: <LANDING.PROFILE_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.featuredDetail.path}/:id`,
		element: <LANDING.FEATURED_DETAIL_MANAGEMENT />,
	},
	// {
	// 	path: `${dashboardPagesMenu.profileServiceManagement.subMenu.serviceDetails.path}/:id`,
	// 	element: <LANDING.SERVICE_DETAILS />,
	// },
	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.profileManagement.path,
	// 	element: <LANDING.PROFILE_MANAGEMENT />,
	// },
	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.serviceManagement.path,
	// 	element: <LANDING.SERVICE_MANAGEMENT />,
	// },

	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.featureManagement.path,
	// 	element: <LANDING.FEATURE_MANAGEMENT />,
	// },
	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.HabitKeyRequest.path,
	// 	element: <LANDING.HABIT_KEY_REQUEST />,
	// },
	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.FeaturedHabitKey.path,
	// 	element: <LANDING.FEATURED_HABIT_KEY />,
	// },
	// {
	// 	path: dashboardPagesMenu.profileServiceManagement.subMenu.featureReview.path,
	// 	element: <LANDING.FEATURE_REVIEWS_MANAGEMENT />,
	// },
	{
		path: dashboardPagesMenu.masseuseDetails.path,
		element: <LANDING.MASSAGE_DETAILS />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.bookings.path,
		element: <LANDING.BOOKING />,
	},
	{
		path: dashboardPagesMenu.addBooking.path,
		element: <LANDING.ADD_BOOKING />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.pastBookings.path,
		element: <LANDING.PAST_BOOKING />,
	},
	{
		path: `${dashboardPagesMenu.bookingManagement.subMenu.bookingDetail.path}/:id`,
		element: <LANDING.BOOKING_DETAILS />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.bookingsCalender.path,
		element: <LANDING.BOOKINGS_CALENDER />,
	},
	{
		path: dashboardPagesMenu.reviewsManagement.path,
		element: <LANDING.REVIEWS_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.massageCenterReviewDetail.path}/:id`,
		element: <LANDING.MASSAGE_CENTER_REVIEW_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.masseuseReviewDetail.path}/:id`,
		element: <LANDING.MASSEUSE_REVIEW_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.serviceReviewDetail.path}/:id`,
		element: <LANDING.SERVICE_REVIEW_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.pastReviewDetail.path}/:id`,
		element: <LANDING.PAST_REVIEW_DETAIL />,
	},
	{
		path: dashboardPagesMenu.notificationManagement.path,
		element: <LANDING.NOTIFICATION_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.financeManagement.subMenu.invoicesManagement.path,
		element: <LANDING.FINANCE_INVOICES_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.financeManagement.subMenu.paymentManagement.path,
		element: <LANDING.FINANCE_PAYMENTS_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.invoiceDetails.path}/:id`,
		element: <LANDING.INVOICE_DETAILS />,
	},
	{
		path: dashboardPagesMenu.helpAndSupport.subMenu.faq.path,
		element: <LANDING.FAQs />,
	},
	{
		path: dashboardPagesMenu.helpAndSupport.subMenu.contactQueries.path,
		element: <LANDING.CONTACT_QUERIES />,
	},
	{
		path: `${dashboardPagesMenu.contactQueryDetail.path}/:id`,
		element: <LANDING.CONTACT_QUERIES_DETAIL />,
	},

	/**
	 * On Boarding Page
	 */
	{
		path: onBoardingMenu.onBoarding.path,
		element: <OnBoardingPage />,
	},

	/**
	 * Review Page
	 */
	{
		path: reviewMenu.blank.path,
		element: <ReviewPage />,
	},

	/**
	 * Auth Page
	 */
	{
		path: 'auth-pages/404',
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Logout />,
	},
	{
		path: 'auth-pages/sign-up',
		element: <Login />,
	},
	{
		path: demoPagesMenu.forgotPassword.path,
		element: <ForgotPassword />,
	},
	{
		path: demoPagesMenu.resetPassword.path,
		element: <ResetPassword />,
	},
];
const contents = [...presentation];

export default contents;
