import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from './store';
import { ReduxDataTypes } from './types';
import { UserResponseData } from '../services/AuthApis/types';
import { OnBoardingData } from '../services/OnBoardingApis/type';
import { PaymentResponseData } from '../services/DashboardApis/type';

const initialState: ReduxDataTypes = {
	token: null,
	userData: null,
	reviewStatus: '',
	isOnboarding: false,
	id: null,
	onBoardingData: null,
	onBoardStep: 1,
	dashboardServiceProvidersCurrentPage: 1,
	masseuseManagementCurrentPage: 1,
	serviceProvidersCurrentPage: 1,
	serviceModalServiceProvidersCurrentPage: 1,
	onBoardMasseuseServiceProvidersCurrentPage: 1,
	onBoardServiceServiceProvidersCurrentPage: 1,
	serviceDetailServiceProvidersCurrentPage: 1,
	serviceCategoryCurrentPage: 1,
	serviceManagementCurrentPage: 1,
	orderListCurrentPage: 1,
	pastOrderListCurrentPage: 1,
	userOrderListCurrentPage: 1,
	userReviewListCurrentPage: 1,
	invoicesListCurrentPage: 1,
	promotionsListCurrentPage: 1,
	massageCenterReviewCurrentPage: 1,
	masseuseReviewCurrentPage: 1,
	serviceReviewCurrentPage: 1,
	detailMassageCenterReviewCurrentPage: 1,
	detailMasseuseReviewCurrentPage: 1,
	detailServiceReviewCurrentPage: 1,
	notificationCurrentPage: 1,
	faqsListCurrentPage: 1,
	conversionListCurrentPage: 1,
	featuredListCurrentPage: 1,
	habitKeyCurrentPage: 1,
	contactQueriesListCurrentPage: 1,
	featuredStep: 0,
	habitStep: 0,
	featuredRequestResponse: null,
	habitKeyRequestResponse: null,
	serviceManagementTab: '1',
	reviewsTab: '1',
	orderDetailsTab: '1',
};

export const adminSlice = createSlice({
	name: 'massageCenter',
	initialState,
	reducers: {
		setUserData: (state, action: PayloadAction<UserResponseData | null | undefined>) => {
			const data = action.payload;
			state.userData = data ?? null;
			state.token = data?.token ?? null;
			state.id = data?.id ?? null;
		},
		setReviewStatus: (state, action: PayloadAction<string>) => {
			const data = action.payload;
			state.reviewStatus = data;
		},
		setIsBoarding: (state, action: PayloadAction<boolean>) => {
			const data = action.payload;
			state.isOnboarding = data;
		},
		setOnBoardingStep: (state, action: PayloadAction<number>) => {
			const data = action.payload;
			state.onBoardStep = data;
		},
		setOnBoardingData: (state, action: PayloadAction<OnBoardingData>) => {
			const data = action.payload;
			state.onBoardingData = data;
		},
		setDashboardServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.dashboardServiceProvidersCurrentPage = action.payload;
		},
		setMasseuseManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.masseuseManagementCurrentPage = action.payload;
		},
		setServiceCategoryCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceCategoryCurrentPage = action.payload;
		},
		setServiceManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceManagementCurrentPage = action.payload;
		},
		setServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceProvidersCurrentPage = action.payload;
		},
		setServiceModalServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceModalServiceProvidersCurrentPage = action.payload;
		},
		setServiceDetailServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceDetailServiceProvidersCurrentPage = action.payload;
		},
		setOnBoardMasseuseServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.onBoardMasseuseServiceProvidersCurrentPage = action.payload;
		},
		setOnBoardServiceServiceProvidersCurrentPage: (state, action: PayloadAction<number>) => {
			state.onBoardServiceServiceProvidersCurrentPage = action.payload;
		},
		setInvoicesListCurrentPage: (state, action: PayloadAction<number>) => {
			state.invoicesListCurrentPage = action.payload;
		},
		setPromotionListCurrentPage: (state, action: PayloadAction<number>) => {
			state.promotionsListCurrentPage = action.payload;
		},
		setMassageCenterReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.massageCenterReviewCurrentPage = action.payload;
		},
		setMasseuseReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.masseuseReviewCurrentPage = action.payload;
		},
		setServiceReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.serviceReviewCurrentPage = action.payload;
		},
		setDetailMassageCenterReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.detailMassageCenterReviewCurrentPage = action.payload;
		},
		setDetailMasseuseReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.detailMasseuseReviewCurrentPage = action.payload;
		},
		setDetailServiceReviewCurrentPage: (state, action: PayloadAction<number>) => {
			state.detailServiceReviewCurrentPage = action.payload;
		},
		setServiceManagementTab: (state, action: PayloadAction<string>) => {
			const data = action.payload;
			state.serviceManagementTab = data;
		},
		setReviewsTab: (state, action: PayloadAction<string>) => {
			const data = action.payload;
			state.reviewsTab = data;
		},
		setOrderDetailsTab: (state, action: PayloadAction<string>) => {
			const data = action.payload;
			state.orderDetailsTab = data;
		},
		setOrderListCurrentPage: (state, action: PayloadAction<number>) => {
			state.orderListCurrentPage = action.payload;
		},
		setPastOrderListCurrentPage: (state, action: PayloadAction<number>) => {
			state.pastOrderListCurrentPage = action.payload;
		},
		setUserOrderListCurrentPage: (state, action: PayloadAction<number>) => {
			state.userOrderListCurrentPage = action.payload;
		},
		setUserReviewListCurrentPage: (state, action: PayloadAction<number>) => {
			state.userReviewListCurrentPage = action.payload;
		},
		setFAQsListCurrentPage: (state, action: PayloadAction<number>) => {
			state.faqsListCurrentPage = action.payload;
		},
		setConversionListCurrentPage: (state, action: PayloadAction<number>) => {
			state.conversionListCurrentPage = action.payload;
		},
		setNotificationCurrentPage: (state, action: PayloadAction<number>) => {
			state.notificationCurrentPage = action.payload;
		},
		setFeaturedListCurrentPage: (state, action: PayloadAction<number>) => {
			state.featuredListCurrentPage = action.payload;
		},
		setHabitKeyListCurrentPage: (state, action: PayloadAction<number>) => {
			state.habitKeyCurrentPage = action.payload;
		},
		setContactQueriesListCurrentPage: (state, action: PayloadAction<number>) => {
			state.contactQueriesListCurrentPage = action.payload;
		},
		setFeaturedStep: (state, action: PayloadAction<number>) => {
			const data = action.payload;
			state.featuredStep = data;
		},
		setFeaturedResponseData: (state, action: PayloadAction<PaymentResponseData>) => {
			const data = action.payload;
			state.featuredRequestResponse = data;
		},
		setHabitStep: (state, action: PayloadAction<number>) => {
			const data = action.payload;
			state.habitStep = data;
		},
		setHabitKeyResponseData: (state, action: PayloadAction<PaymentResponseData>) => {
			const data = action.payload;
			state.habitKeyRequestResponse = data;
		},
		setLogout: () => {
			return initialState;
		},
	},
});

export const {
	setUserData,
	setReviewStatus,
	setIsBoarding,
	setOnBoardingData,
	setDashboardServiceProvidersCurrentPage,
	setMasseuseManagementCurrentPage,
	setServiceCategoryCurrentPage,
	setServiceManagementCurrentPage,
	setServiceProvidersCurrentPage,
	setServiceDetailServiceProvidersCurrentPage,
	setServiceModalServiceProvidersCurrentPage,
	setOnBoardMasseuseServiceProvidersCurrentPage,
	setOnBoardServiceServiceProvidersCurrentPage,
	setOrderListCurrentPage,
	setPastOrderListCurrentPage,
	setUserOrderListCurrentPage,
	setUserReviewListCurrentPage,
	setInvoicesListCurrentPage,
	setPromotionListCurrentPage,
	setMassageCenterReviewCurrentPage,
	setMasseuseReviewCurrentPage,
	setServiceReviewCurrentPage,
	setDetailMassageCenterReviewCurrentPage,
	setDetailMasseuseReviewCurrentPage,
	setDetailServiceReviewCurrentPage,
	setFAQsListCurrentPage,
	setNotificationCurrentPage,
	setConversionListCurrentPage,
	setFeaturedListCurrentPage,
	setHabitKeyListCurrentPage,
	setContactQueriesListCurrentPage,
	setServiceManagementTab,
	setOnBoardingStep,
	setFeaturedStep,
	setHabitStep,
	setFeaturedResponseData,
	setHabitKeyResponseData,
	setReviewsTab,
	setOrderDetailsTab,
	setLogout,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;

export const token = (state: RootState) => state?.admin?.token;
