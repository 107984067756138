import { RequestInvoices } from '../../type/invoicesApiTypes/invoicesApiTypes';
import adminApi from '../adminApi';
import {
	CategoryData,
	MasseusesData,
	ServiceData,
	ProfileData,
	SlotsRequest,
	HabitKeyRequest,
	RequestMasseuseListFilterType,
	UpdateMasseuseRequestData,
	DeleteMasseuseRequestData,
	RequestServicesListFilterType,
	AddPromotionRequestData,
	UpdatePromotionRequestData,
	ConfirmPaymentPayload,
	CancelOrderPayload,
	ConversionRequestData,
	BookOrderPayload,
	FeaturedPayload,
	AddQueryRequestData,
} from './type';

const DashboardApi = adminApi.injectEndpoints({
	endpoints: (builder: any) => ({
		getDashboard: builder.mutation({
			query: () => ({
				url: 'massage-center/dashboard',
				method: 'GET',
			}),
		}),
		getDashboardKpis: builder.mutation({
			query: ({ startDate, endDate }: { startDate: string; endDate: string }) => ({
				url: 'massage-center/kips',
				method: 'GET',
				params: { startDate, endDate },
			}),
		}),
		getUserList: builder.mutation({
			query: ({ currentPage }: { currentPage: number }) => ({
				url: 'massage-center/get-users',
				method: 'GET',
				params: { currentPage },
			}),
		}),
		getUserDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/get-user/${id}`,
				method: 'GET',
			}),
		}),
		getMasseuseList: builder.mutation({
			query: ({
				centerId,
				currentPage,
				perPage,
				topPerformance,
				filter,
			}: {
				centerId: string;
				currentPage: number;
				perPage: number;
				topPerformance: boolean;
				filter: RequestMasseuseListFilterType;
			}) => ({
				url: `massage-center/${centerId}/masseuses`,
				method: 'GET',
				params: { currentPage, perPage, topPerformance, filter },
			}),
		}),
		getMasseuseDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/masseuses/${id}`,
				method: 'GET',
			}),
		}),
		addMasseuse: builder.mutation({
			query: ({ centerId, payload }: { centerId: string; payload: MasseusesData }) => ({
				url: `massage-center/${centerId}/masseuse`,
				method: 'POST',
				body: payload,
			}),
		}),
		updateMasseuseDetails: builder.mutation({
			query: ({ id, payload }: { id: string; payload: UpdateMasseuseRequestData }) => ({
				url: `massage-center/masseuse/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		deleteMasseuse: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/masseuse/${id}`,
				method: 'DELETE',
			}),
		}),
		deleteServiceProvider: builder.mutation({
			query: ({ id, payload }: { id: string; payload: DeleteMasseuseRequestData }) => ({
				url: `massage-center/masseuse/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		addCategory: builder.mutation({
			query: (payload: string) => ({
				url: `massage-center/category`,
				method: 'POST',
				body: payload,
			}),
		}),
		getCategories: builder.mutation({
			query: ({
				currentPage,
				isAllCategories,
			}: {
				currentPage: number;
				isAllCategories: boolean;
			}) => ({
				url: `massage-center/categories`,
				method: 'GET',
				params: { currentPage, isAllCategories },
			}),
		}),
		updateCategory: builder.mutation({
			query: ({ id, payload }: { id: string; payload: CategoryData }) => ({
				url: `massage-center/category/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		deleteCategory: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/category/${id}`,
				method: 'DELETE',
			}),
		}),
		addService: builder.mutation({
			query: ({ centerId, payload }: { centerId: string; payload: ServiceData }) => ({
				url: `massage-centers/${centerId}/service`,
				method: 'POST',
				body: payload,
			}),
		}),
		// getServices: builder.mutation({
		// 	query: ({ currentPage }: { currentPage: number }) => ({
		// 		url: `massage-center/services`,
		// 		method: 'GET',
		// 		params: { currentPage },
		// 	}),
		// }),
		// getServices: builder.mutation({
		// 	query: ({
		// 		centerId,
		// 		filter,
		// 		currentPage,
		// 		perPage,
		// 		isAllServices,
		// 	}: {
		// 		currentPage: number;
		// 		perPage: number;
		// 		centerId: string;
		// 		isAllServices: boolean;
		// 		filter: RequestServicesListFilterType;
		// 	}) => ({
		// 		url: `massage-centers/${centerId}/services`,
		// 		method: 'GET',
		// 		params: { currentPage, perPage, filter, isAllServices },
		// 	}),
		// }),
		getServices: builder.mutation({
			query: ({
				centerId,
				filter,
				currentPage,
				perPage,
				isAllServices,
			}: {
				centerId: string;
				filter: RequestServicesListFilterType;
				currentPage: number;
				perPage: number;
				isAllServices: boolean;
			}) => ({
				url: `massage-centers/${centerId}/services`,
				method: 'GET',
				params: filter,
				currentPage,
				perPage,
				isAllServices,
			}),
		}),
		getServiceDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-centers/services/${id}`,
				method: 'GET',
			}),
		}),
		deleteService: builder.mutation({
			query: (id: string) => ({
				url: `massage-centers/services/${id}`,
				method: 'DELETE',
			}),
		}),
		updateService: builder.mutation({
			query: ({ serviceId, payload }: { serviceId: string; payload: ServiceData }) => ({
				url: `massage-centers/services/${serviceId}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		addFeatureService: builder.mutation({
			query: (payload: SlotsRequest) => ({
				url: 'massage-center/request-feature-service',
				method: 'POST',
				body: payload,
			}),
		}),
		getFeaturedService: builder.mutation({
			query: ({ year, month }: { year: number; month: string }) => ({
				url: 'massage-center/featured-service',
				method: 'GET',
				params: { year, month },
			}),
		}),
		getSlots: builder.mutation({
			query: ({ year, month }: { year: number; month: string }) => ({
				url: 'massage-center/slots',
				method: 'GET',
				params: { year, month },
			}),
		}),
		getProfile: builder.mutation({
			query: () => ({
				url: 'massage-center/profile',
				method: 'GET',
			}),
		}),
		updateProfile: builder.mutation({
			query: ({ payload }: { payload: ProfileData }) => ({
				url: `massage-center/profile`,
				method: 'PUT',
				body: payload,
			}),
		}),
		enrollHabitKey: builder.mutation({
			query: (payload: HabitKeyRequest) => ({
				url: 'massage-center/enroll-habit-key',
				method: 'POST',
				body: payload,
			}),
		}),
		// ORDERS
		getOrdersList: builder.mutation({
			query: ({
				centerId,
				currentPage,
				perPage,
				masseuseId,
				userId,
				startDate,
				endDate,
				timePeriod,
				isPastBookings,
				isAllOrders,
			}: {
				centerId: string;
				currentPage: number;
				perPage: number;
				masseuseId: string;
				userId: string;
				startDate: string;
				endDate: string;
				timePeriod: string;
				isPastBookings: boolean;
				isAllOrders: boolean;
			}) => ({
				url: `massage-center/orders`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
					masseuseId,
					userId,
					centerId,
					startDate,
					endDate,
					timePeriod,
					isPastBookings,
					isAllOrders,
				},
			}),
		}),
		getOrderDetail: builder.mutation({
			query: (id: string) => ({
				url: `orders/${id}`,
				method: 'GET',
			}),
		}),
		updateOrder: builder.mutation({
			query: ({ id, payload }: { id: string; payload: CancelOrderPayload }) => ({
				url: `massage-center/orders/${id}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		getServiceSlots: builder.mutation({
			query: ({
				serviceId,
				masseuseId,
				date,
				duration,
				startTime,
			}: {
				serviceId: string;
				masseuseId: string;
				date?: string;
				duration?: string;
				startTime?: string;
			}) => ({
				url: `massage-center/services/${serviceId}/slots`,
				method: 'GET',
				params: {
					masseuseId,
					date,
					duration,
					startTime,
				},
			}),
		}),
		postOrder: builder.mutation({
			query: (payload: BookOrderPayload) => ({
				url: `massage-center/orders/book`,
				method: 'POST',
				body: payload,
			}),
		}),
		// Invoices
		// postInvoices: builder.mutation({
		// 	query: (payload: RequestInvoices) => ({
		// 		url: 'invoices',
		// 		method: 'POST',
		// 		body: payload,
		// 	}),
		// }),
		postInvoices: builder.mutation({
			query: (payload: RequestInvoices) => ({
				url: 'massage-center/invoices/admin',
				method: 'POST',
				body: payload,
			}),
		}),
		// getInvoicesList: builder.mutation({
		// 	query: ({
		// 		centerId,
		// 		currentPage,
		// 		perPage,
		// 		status,
		// 	}: {
		// 		centerId: string;
		// 		currentPage: number;
		// 		perPage: number;
		// 		status: string;
		// 	}) => ({
		// 		url: `invoices`,
		// 		method: 'GET',
		// 		params: {
		// 			currentPage,
		// 			perPage,
		// 			centerId,
		// 			status,
		// 		},
		// 	}),
		// }),
		getInvoicesList: builder.mutation({
			query: ({
				currentPage,
				perPage,
				status,
			}: {
				currentPage: number;
				perPage: number;
				status: string;
			}) => ({
				url: `massage-center/invoices/admin`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
					status,
				},
			}),
		}),
		// getInvoiceDetail: builder.mutation({
		// 	query: (invoiceId: string) => ({
		// 		url: `invoices/${invoiceId}`,
		// 		method: 'GET',
		// 	}),
		// }),
		getInvoiceDetail: builder.mutation({
			query: (invoiceId: string) => ({
				url: `massage-center/invoices/${invoiceId}/admin`,
				method: 'GET',
			}),
		}),
		// PROMOTIONS
		addPromotion: builder.mutation({
			query: (payload: AddPromotionRequestData) => ({
				url: `massage-center/promotions`,
				method: 'POST',
				body: payload,
			}),
		}),
		getPromotionList: builder.mutation({
			query: ({
				currentPage,
				perPage,
				isActive,
			}: {
				currentPage: number;
				perPage: number;
				isActive: boolean;
			}) => ({
				url: `massage-center/promotions`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
					isActive,
				},
			}),
		}),
		getPromotionDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/promotions/${id}`,
				method: 'GET',
			}),
		}),
		updatePromotionDetail: builder.mutation({
			query: ({
				promotionId,
				payload,
			}: {
				promotionId: string;
				payload: UpdatePromotionRequestData;
			}) => ({
				url: `promotions/${promotionId}`,
				method: 'PUT',
				body: payload,
			}),
		}),
		// PAYMENT APIS
		invoicePayment: builder.mutation({
			query: (invoiceId: string) => ({
				url: `massage-center/invoices/${invoiceId}/payment`,
				method: 'POST',
			}),
		}),
		confirmPayment: builder.mutation({
			query: ({
				invoiceId,
				payload,
			}: {
				invoiceId: string;
				payload: ConfirmPaymentPayload;
			}) => ({
				url: `massage-center/invoices/${invoiceId}/confirm-payment`,
				method: 'POST',
				body: payload,
			}),
		}),
		// ENVELOPES & DOCUSIGN APIS
		sendEnvelopes: builder.mutation({
			query: () => ({
				url: `envelopes/send`,
				method: 'POST',
			}),
		}),
		getAgreement: builder.mutation({
			query: () => ({
				url: `agreement/download`,
				method: 'GET',
				responseHandler: (response: any) => response.blob(),
			}),
		}),
		// REVIEWS
		getReviews: builder.mutation({
			query: ({
				userId,
				currentPage,
				perPage,
				type,
			}: {
				userId: string;
				currentPage: number;
				perPage: number;
				type: string;
			}) => ({
				url: `massage-center/reviews`,
				method: 'GET',
				params: { userId, currentPage, perPage, type },
			}),
		}),
		getCenterReviewDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/reviews/${id}`,
				method: 'GET',
			}),
		}),
		// FAQs
		getFAQs: builder.mutation({
			query: () => ({
				url: `massage-center/faqs`,
				method: 'GET',
			}),
		}),
		getFAQsDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/faqs/${id}`,
				method: 'GET',
			}),
		}),
		// Notifications
		getNotifications: builder.mutation({
			query: ({
				currentPage,
				perPage,
				isRead,
			}: {
				currentPage: number;
				perPage: number;
				isRead: boolean;
			}) => ({
				url: `massage-center/notifications`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
					isRead,
				},
			}),
		}),
		// CONVERSATIONS
		getConversations: builder.mutation({
			query: ({
				orderId,
				currentPage,
				perPage,
			}: {
				orderId: string;
				currentPage: number;
				perPage: number;
			}) => ({
				url: `massage-center/orders/${orderId}/conversations`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
				},
			}),
		}),
		postConversations: builder.mutation({
			query: ({ orderId, payload }: { orderId: string; payload: ConversionRequestData }) => ({
				url: `massage-center/orders/${orderId}/conversations`,
				method: 'POST',
				body: payload,
			}),
		}),
		// RATING
		getGoogleRating: builder.mutation({
			query: ({ centerId }: { centerId: string }) => ({
				url: `massage-center/${centerId}/google-rating`,
				method: 'GET',
			}),
		}),
		getYelpRating: builder.mutation({
			query: ({ centerId }: { centerId: string }) => ({
				url: `massage-center/${centerId}/yelp-rating`,
				method: 'GET',
			}),
		}),
		// FEATURED
		postFeatured: builder.mutation({
			query: (payload: FeaturedPayload) => ({
				url: `massage-center/featured`,
				method: 'POST',
				body: payload,
			}),
		}),
		getFeatured: builder.mutation({
			query: () => ({
				url: `massage-center/featured`,
				method: 'GET',
			}),
		}),
		getFeaturedList: builder.mutation({
			query: ({
				currentPage,
				perPage,
				isFeatured,
			}: {
				currentPage: number;
				perPage: number;
				isFeatured: boolean;
			}) => ({
				url: `massage-center/featured/history`,
				method: 'GET',
				params: { currentPage, perPage, isFeatured },
			}),
		}),
		confirmFeaturedPayment: builder.mutation({
			query: ({
				requestId,
				payload,
			}: {
				requestId: string;
				payload: ConfirmPaymentPayload;
			}) => ({
				url: `massage-center/featured/${requestId}/confirm-payment`,
				method: 'POST',
				body: payload,
			}),
		}),
		getFeaturedDetail: builder.mutation({
			query: (id: string) => ({
				url: `massage-center/featured/${id}`,
				method: 'GET',
			}),
		}),
		// HABIT-KEY
		postHabitKey: builder.mutation({
			query: (payload: FeaturedPayload) => ({
				url: `massage-center/habit-key`,
				method: 'POST',
				body: payload,
			}),
		}),
		getHabitKey: builder.mutation({
			query: () => ({
				url: `massage-center/habit-key`,
				method: 'GET',
			}),
		}),
		confirmHabitKey: builder.mutation({
			query: ({
				requestId,
				payload,
			}: {
				requestId: string;
				payload: ConfirmPaymentPayload;
			}) => ({
				url: `massage-center/habit-key/${requestId}/complete-payment`,
				method: 'PUT',
				body: payload,
			}),
		}),
		getHabitKeyHistory: builder.mutation({
			query: ({
				currentPage,
				perPage,
				status,
			}: {
				currentPage: number;
				perPage: number;
				status: string;
			}) => ({
				url: `massage-center/habit-key/history`,
				method: 'GET',
				params: { currentPage, perPage, status },
			}),
		}),
		// CONTACT QUERIES
		getContactQueries: builder.mutation({
			query: ({
				currentPage,
				perPage,
				status,
			}: {
				currentPage: number;
				perPage: number;
				status: string;
			}) => ({
				url: `massage-center/queries`,
				method: 'GET',
				params: {
					currentPage,
					perPage,
					status,
				},
			}),
		}),
		getContactQueriesDetail: builder.mutation({
			query: (queryId: string) => ({
				url: `massage-center/queries/${queryId}`,
				method: 'GET',
			}),
		}),
		addQuery: builder.mutation({
			query: (payload: AddQueryRequestData) => ({
				url: `massage-center/queries`,
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetDashboardMutation,
	useGetDashboardKpisMutation,
	useGetUserListMutation,
	useGetUserDetailMutation,
	useGetMasseuseListMutation,
	useGetMasseuseDetailMutation,
	useAddMasseuseMutation,
	useUpdateMasseuseDetailsMutation,
	useDeleteMasseuseMutation,
	useDeleteServiceProviderMutation,
	useAddCategoryMutation,
	useGetCategoriesMutation,
	useUpdateCategoryMutation,
	useDeleteCategoryMutation,
	useAddServiceMutation,
	useAddFeatureServiceMutation,
	useGetServicesMutation,
	useGetServiceDetailMutation,
	useDeleteServiceMutation,
	useUpdateServiceMutation,
	useGetProfileMutation,
	useUpdateProfileMutation,
	useGetSlotsMutation,
	useGetFeaturedServiceMutation,
	useEnrollHabitKeyMutation,
	useGetOrdersListMutation,
	useGetOrderDetailMutation,
	useUpdateOrderMutation,
	useGetServiceSlotsMutation,
	usePostOrderMutation,
	useGetInvoicesListMutation,
	usePostInvoicesMutation,
	useGetInvoiceDetailMutation,
	useAddPromotionMutation,
	useGetPromotionListMutation,
	useGetPromotionDetailMutation,
	useUpdatePromotionDetailMutation,
	useInvoicePaymentMutation,
	useConfirmPaymentMutation,
	useSendEnvelopesMutation,
	useGetAgreementMutation,
	useGetReviewsMutation,
	useGetCenterReviewDetailMutation,
	useGetFAQsMutation,
	useGetFAQsDetailMutation,
	useGetNotificationsMutation,
	useGetConversationsMutation,
	usePostConversationsMutation,
	useGetGoogleRatingMutation,
	useGetYelpRatingMutation,
	usePostFeaturedMutation,
	useGetFeaturedListMutation,
	useGetFeaturedMutation,
	useConfirmFeaturedPaymentMutation,
	useGetFeaturedDetailMutation,
	usePostHabitKeyMutation,
	useGetHabitKeyMutation,
	useConfirmHabitKeyMutation,
	useGetHabitKeyHistoryMutation,
	useGetContactQueriesMutation,
	useGetContactQueriesDetailMutation,
	useAddQueryMutation,
} = DashboardApi;
