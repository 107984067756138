import React, { FC, useState, KeyboardEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { EyeOutlined, EyeInvisibleOutlined, EyeFilled } from '@ant-design/icons';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import classNames from 'classnames';

import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { setIsBoarding, setReviewStatus, setUserData } from '../../../redux/slice';
import { LoginResponse, SignupResponse } from '../../../services/AuthApis/types';
import { ErrorResponseType } from '../../../services/ErrorResponseType/ErrorResponseType';
import { usePostLoginMutation, usePostSignupMutation } from '../../../services/AuthApis/authApi';
import { demoPagesMenu, onBoardingMenu, reviewMenu } from '../../../menu';
import { RootState } from '../../../redux/store';
import Logo from '../../../assets/svg/LoginLogo.svg';
import { EmailValidation, PasswordValidation } from '../../../utils/utils';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, { CardBody } from '../../../components/bootstrap/Card';

const ResetPassword: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token, onBoardStep } = useSelector((state: RootState) => state.admin);
	const { darkModeStatus } = useDarkMode();
	const [postLogin] = usePostLoginMutation();
	const [postSignup] = usePostSignupMutation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [checked, setChecked] = useState(false);
	const [forgotPasswordStep, setForgotPasswordStep] = useState<number>(1);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
	};

	const loginHandler = async () => {
		setIsLoading(true);
		try {
			if (email.length === 0 || password.length === 0) {
				message.error('please fill data in fields');
				return;
			}

			if (EmailValidation(email) === false) {
				message.error(`Please Enter Valid Email`);
				return;
			}

			await postLogin({ email, password })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as LoginResponse;
					if (result) {
						dispatch(setUserData(data));
						dispatch(setReviewStatus(data?.status));
						dispatch(setIsBoarding(data?.isOnboard));
						setIsLoading(false);
						if (!data?.isOnboard) {
							navigate(`../${onBoardingMenu.onBoarding.path}`);
						} else if (
							data?.isOnboard &&
							(data?.status === null || data?.status === 'pending')
						) {
							navigate(`../${reviewMenu.blank.path}`);
						} else {
							navigate('/');
						}
						message.success(msg);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	const signUpHandler = async () => {
		setIsLoading(true);
		try {
			if (email.length === 0 || password.length === 0 || confirmPassword.length === 0) {
				message.error('please fill data in fields');
				return;
			}

			if (EmailValidation(email) === false) {
				message.error(`Please Enter Valid Email`);
				return;
			}

			if (PasswordValidation(password) === false) {
				message.error(`Please Enter Valid Password`);
				return;
			}

			if (password.length < 6 || confirmPassword.length < 6) {
				message.error('password or Confirm-password must be 6 characters long');
				return;
			}

			if (password !== confirmPassword) {
				message.error('password and Confirm-password must be same');
				return;
			}
			await postSignup({ email, password, confirmPassword })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as SignupResponse;
					if (result) {
						dispatch(setUserData(data));
						dispatch(setIsBoarding(data?.isOnboard));
						setIsLoading(false);
						if (!data?.isOnboard) {
							navigate(`../${onBoardingMenu.onBoarding.path}`);
						} else {
							navigate('/');
						}
						message.success(msg);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	const onChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
	};

	// const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
	// 	if (event.key === 'Enter') {
	// 		if (singUpStatus) {
	// 			signUpHandler();
	// 		} else {
	// 			loginHandler();
	// 		}
	// 	}
	// };

	return (
		<div className='row col-12 h-100 align-items-center justify-content-center'>
			<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
				<Card className='' data-tour='reset-password-page'>
					<CardBody>
						<div className='text-center my-1'>
							<div
								className={classNames('text-decoration-none fw-bold display-2', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								<img src={Logo} alt='logo' height={100} width={100} />
								<p className='h4 fw-bold'>Massage Key</p>
							</div>
						</div>
						<div className='row col-12 p-0 m-0'>
							<div className='row' style={{ margin: '0 auto' }}>
								<form className='row'>
									<div className='row col-12 justify-content-center align-content-center py-5'>
										<div className='py-1 mt-0'>
											<div className='text-center h1 fw-bold mt-0 mb-5'>
												Reset Password
											</div>
											<Label htmlFor='Password' className='m-lg-2'>
												New Password
											</Label>
											<div className='input-group mb-4'>
												<Input
													className='py-3'
													type={showPassword ? 'text' : 'password'}
													autoComplete='current-password'
													placeholder='Enter password'
													value={password}
													onChange={(e: any) =>
														setPassword(e.target.value.trim())
													}
													onKeyPress={() => {}}
												/>
												<span
													className='input-group-text'
													role='button'
													tabIndex={0}
													// onClick={() => {}}
													aria-label={
														showPassword
															? 'Hide password'
															: 'Show password'
													}>
													{showPassword ? (
														<IoEyeOffOutline size={20} />
													) : (
														<IoEyeOutline size={20} />
													)}
												</span>
											</div>
											<Label htmlFor='Password' className='m-lg-2'>
												Confirm Password
											</Label>
											<div className='input-group mb-4'>
												<Input
													className='py-3'
													type={showConfirmPassword ? 'text' : 'password'}
													autoComplete='current-password'
													placeholder='Enter Confirm Password'
													value={confirmPassword}
													onChange={() => {}}
													onKeyPress={() => {}}
												/>
												<span
													className='input-group-text'
													role='button'
													tabIndex={0}
													aria-label={
														showConfirmPassword
															? 'Hide password'
															: 'Show password'
													}>
													{showConfirmPassword ? (
														<IoEyeOffOutline size={20} />
													) : (
														<IoEyeOutline size={20} />
													)}
												</span>
											</div>
											<Button
												isDisable={
													checked === false ||
													email.length === 0 ||
													password.length === 0 ||
													confirmPassword.length === 0
												}
												color='info'
												className='w-100 py-3 mt-5 rounded-pill'
												onClick={() => {}}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Reset
											</Button>
											<div className='col-12 mt-5'>
												<p className='' style={{ textAlign: 'center' }}>
													<a
														href=''
														className='fw-bold cursor-pointer text-decoration-none'
														onClick={() => {
															setForgotPasswordStep(2);
															setEmail('');
															setPassword('');
															navigate(
																`../${demoPagesMenu.forgotPassword.path}`,
															);
														}}>
														Go back
													</a>
												</p>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};
export default ResetPassword;
