// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Input from './bootstrap/forms/Input';

type MapPickerProps = {
	initialPosition: { lat: number; lng: number };
	isInputVisible?: boolean;
	onLocationSelect?: (location: { lat: number; lng: number }) => void;
};

const redMarkerIcon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';

const containerStyle = {
	width: '100%',
	height: '400px',
};

const MapPicker: React.FC<MapPickerProps> = ({
	initialPosition,
	isInputVisible,
	onLocationSelect,
}) => {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
		libraries: ['places'],
	});

	const [selectedPosition, setSelectedPosition] = useState<{ lat: number; lng: number }>(
		initialPosition,
	);
	const [inputVisible, setInputVisible] = useState(isInputVisible);
	const [inputValue, setInputValue] = useState('');
	const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const onMapClick = (event: google.maps.MapMouseEvent) => {
		if (event.latLng && onLocationSelect) {
			const position = {
				lat: event.latLng.lat(),
				lng: event.latLng.lng(),
			};
			setSelectedPosition(position);
			onLocationSelect(position);
		}
	};

	const onPlaceChanged = () => {
		if (autocompleteRef.current) {
			const place = autocompleteRef.current.getPlace();
			if (place.geometry && place.geometry.location) {
				const position = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
				};
				setSelectedPosition(position);
				if (onLocationSelect) {
					onLocationSelect(position);
				}
				setInputValue(place.formatted_address || '');
			}
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current);
			autocompleteRef.current.addListener('place_changed', onPlaceChanged);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded]);

	if (!isLoaded) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{inputVisible && (
				<Input
					ref={inputRef}
					type='text'
					placeholder='Search for a location'
					value={inputValue}
					onChange={(e: any) => setInputValue(e.target.value)}
					style={{
						marginBottom: '10px',
						width: '100%',
						padding: '10px',
						fontSize: '12px',
					}}
				/>
			)}
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={selectedPosition}
				zoom={10}
				onClick={onLocationSelect ? onMapClick : undefined}>
				<MarkerF position={selectedPosition} icon={redMarkerIcon} />
			</GoogleMap>
		</div>
	);
};

export default MapPicker;
